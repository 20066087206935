<template>
  <div class="">
    <div class="top-button-area p-m-2">
      <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_UrunTurModal()" />
    </div>
    <DataTable v-if="DataTable_render" :columns="columns" :data="UrunTurListesi" v-on:set-child-data="getDataChild" />

    <Dialog v-model:visible="UrunTurModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '25rem' }" :modal="true">
      <template #header>
        <h4>Yeni Ürün Türü</h4>
      </template>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12">
          <span for="spanUrunTur">Tür Adı</span>
          <InputText type="text" v-model="v$.UrunTur.$model" aria-labelledby="spanUrunTur"
            :class="{ 'p-invalid': v$.UrunTur.$invalid && submitted }" />
          <small v-if="(v$.UrunTur.$invalid && submitted) || v$.UrunTur.$pending.$response
        " class="p-error">{{ v$.UrunTur.required.$message.replace("Value", "Name") }}</small>
        </div>
      </div>

      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._UrunTurModal()" />
      </template>
    </Dialog>

  </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "@/services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      UrunTurModal: false,
      UrunTurListesi: [],
      columns: [
        { field: "Ad", header: "Ad" },
      ],
      UrunTur: "",
      UrunTur_rowid: ""
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.UrunTurModal = !this.UrunTurModal;
      this.UrunTurModelDurum = false;
      this.UrunTur_rowid = getdata_child.data.UrunTur_rowid;
      let data = {
        UrunTur_rowid: getdata_child.data.UrunTur_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetUrunTurInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.UrunTur.$model = res.data[0].UrunTurAd;
          this.UrunTur_rowid = res.data[0].UrunTur_rowid;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _UrunTurModal() {
      this.UrunTurModelDurum = true;
      this.UrunTurModal = !this.UrunTurModal;
      if (!this.UrunTurModal) {
        // this.resetForm();
      }
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          UrunTur_rowid: this.UrunTur_rowid,
          UrunTurAd: this.UrunTur,
        };
        if (this.UrunTurModelDurum) {
          // Yeni UrunTur
          GlobalServis.GlobalServis("POST", "SetUrunTur", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Tür oluşturulmuştur",
                life: 3000,
              });
              this.Refresh();
              this._UrunTurModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        } else {
          // Edit UrunTur
          GlobalServis.GlobalServis("POST", "UpdateUrunTur", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Tür düzenlemiştir",
                life: 3000,
              });
              this.Refresh();
              this._UrunTurModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetUrunTurTablo", "").then((res) => {
        if (res.status == 200) {
          this.UrunTurListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    }
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      UrunTur: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
